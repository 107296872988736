.logo {
    cursor: pointer;
}

.logo__img {
    max-width: 38px;
    object-fit: contain;
    object-position: left;
    max-height: 100%;
    box-sizing: contain-box;
}

.logo:hover {
    opacity: .8;
}