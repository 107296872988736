.movies-card-list {
    padding: 70px 70px 26px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    gap: 58px 30px;
    box-sizing: border-box;
}

.movies-card-list__nothing-found {
    margin: 0;
    position: absolute;
    width: 100%;
    right: 0;
    text-align: center;
}


@media screen and (max-width: 768px) {
    .movies-card-list {
        padding: 70px 30px 10px;
        grid-template-columns: repeat(2, 1fr);
        gap: 45px 30px;
    }
}

@media screen and (max-width: 480px) {
    .movies-card-list {
        padding: 40px 10px 10px;
        grid-template-columns: repeat(1, 1fr);
        gap: 45px;
    }
}