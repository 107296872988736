.filter-checkbox {
    height: 0;
}

.filter-checkbox__input {
    display: none;
}

.filter-checkbox__label {
    transform: translateY(-56px);
    padding-right: 30px;
    display: flex;
    justify-content: end;
    align-items: center;
    min-height: 40px;
    width: 196px;
    border-left: 1px var(--dark5) solid;
    color: var(--dark);
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    background-position: left 29px center;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-left: auto;
}

.filter-checkbox__label_checked {
    background-image: url(../../../images/checkbox-on.svg);
}

.filter-checkbox__label_off {
    background-image: url(../../../images/checkbox-off.svg);
}

@media screen and (max-width: 600px) {
    .filter-checkbox__label {
        width: 148px;
        border: 0;
        box-sizing: border-box;
        font-size: 11px;
        padding: 0 0 0 49px;
        margin: 48px auto 0;
        transform: inherit;
        justify-content: center;
        background-position: left center;
        height: auto;
    }
}

@media screen and (max-width: 600px) {
    .filter-checkbox {
        min-height: 109px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .filter-checkbox__label {
        min-height: auto;
        margin: 0;
    }
}