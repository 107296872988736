.navigation__container {
    display: flex;
    flex-direction: row;
    gap: 50px;
}

.navigation__bar {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

.navigation__bar_hidden {
    display: none;
}

.navigation__burger {
    display: none;
}

.navigation__button {
    display: flex;
    flex-direction: row;
    width: 100px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}

.navigation__button_hidden {
    display: none;
}

.navigation__button_filled {
    background-color: var(--dark);
    border-radius: 6px;
    padding: 0 20px;
    min-height: 32px;
    width: auto;
}

.navigation__link {
    color: var(--dark);
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    margin: 0;
    display: block;
    font-weight: 400;
    line-height: 18px;
    font-size: 14px;
}

.navigation__link_inside-filled-button {
    color: var(--light);
}

.navigation__link_current {
    font-weight: 500;
}

.navigation__link_main {
    display: none;
}

.navigation__button:hover .navigation__link {
    opacity: .7;
}

.navigation__link:hover {
    opacity: .7;
}

.navigation__button:hover {
    opacity: .7;
}

.navigation__button:hover .navigation__link_inside-filled-button {
    opacity: 1;
}

.navigation__profile-icon {
    cursor: pointer;
    background-image: url(../../images/profile.svg);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-color: var(--dark);
    border-radius: 6px;
    width: 30px;
    min-height: 30px;
}

.navigation__profile-icon_light {
    background-image: url(../../images/profile-dark.svg);
    background-color: var(--light3);
}

@media screen and (max-width: 768px) {
    .navigation_mobile {
        position: absolute;
        height: 100vh;
        left: 0;
        top: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, .3);
    }

    .navigation__container {
        background-color: var(--light);
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start; 
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 520px;
        align-items: center;
        padding-bottom: 48px;
        box-sizing: border-box;
    }

    .navigation__bar.navigation__bar_authorized {
        margin-top: 85px;
        flex-direction: column;
        gap: 22px;
    }

    .navigation__bar.navigation__bar_authorized > .navigation__link_main {
        display: block;
    }

    .navigation__bar.navigation__bar_authorized > .navigation__link {
        padding-bottom: 6px;
        line-height: auto;
        font-size: 18px;
    }

    .navigation__bar.navigation__bar_authorized > .navigation__button > .navigation__link {
        font-size: 14px;
    }

    .navigation__bar.navigation__bar_authorized > .navigation__button_hidden {
        display: none;
    }

    .navigation__bar.navigation__bar_authorized > .navigation__link_current {
        padding-bottom: 4px;
        border-bottom: 2px var(--dark) solid;
    }

    .navigation__burger {
        display: block;
        border: none;
        background-image: url(../../images/burger.svg);
        background-size: 30px;
        background-color: transparent;
        background-repeat: no-repeat;
        min-height: 30px;
        width: 30px;
        margin: 22px 22px 22px auto;
        cursor: pointer;
    }

    .navigation__burger:hover {
        opacity: .7;
    }

    .navigation__button_profile {
        margin-top: auto;
    }

    .navigation__burger_close {
        background-image: url(../../images/close-to-burger.svg);
    }

    .navigation__profile-icon {
        background-image: url(../../images/profile-dark.svg);
        background-color: var(--light3);
    }

    .navigation_mobile-menu-hidden {
        max-height: 74px;
        background-color: transparent;
        width: 74px;
        right: 0;
        left: auto;
    }

    .navigation_mobile-menu-hidden > .navigation__container {
        min-height: 74px;
        background-color: transparent;
        padding: 0;
        width: 74px;
    }

    .navigation_mobile-menu-hidden > .navigation__container > .navigation__bar {
        display: none;
    }

    .navigation_mobile-menu-hidden > .navigation__container > .navigation__button {
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .navigation__container {
        width: 100%;
    }
}