.footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 70px;
    margin-top: auto;
    box-sizing: border-box;
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}

.footer__bar {
    display: flex;
    justify-content: space-between;
    border-top: 1px var(--light7) solid;
    padding: 20px 0;
}

.footer__text {
    padding: 80px 0 20px;
    color: var(--dark4);
}

.footer__copyright {
    color: var(--dark);
    margin: 0;
}

.footer__links {
    display: flex;
    gap: 20px;
    margin: 0;
}

.footer__link {
    color: var(--dark);
    cursor: pointer;
    text-decoration: none;
    margin: 0;
}

.footer__link:hover {
    opacity: .7;
}

@media screen and (max-width: 1280px) {
    .footer {
        padding: 0 70px;
    }   
}

@media screen and (max-width: 768px) {
    .footer {
        padding: 0 30px;
    }   
}

@media screen and (max-width: 480px) {
    .footer {
        padding: 0 10px;
        font-size: 12px;
    }   

    .footer__bar {
        flex-direction: column-reverse;
        padding: 30px 0 20px;
        gap: 30px;
        justify-content: unset;
    }

    .footer__links {
        flex-direction: column;
        gap: 12px;
    }

    .footer__copyright {
        color: var(--dark4);
    }
}