:root {
    --light-violet: #F3C1F8;
    --red: #FF004C;
    --dark: #000;
    --dark2: #4a4a4a;
    --dark3: #959595;
    --dark4: #a0a0a0;
    --dark5: #cbcbcb;
    --dark6: #c4c4c4;
    --dark7: #c2c2c2;
    --mid: #e6e6e6;
    --light7: #e8e8e8;
    --light6: #f2f2f2;
    --light5: #f5f5f5;
    --light4: #f8f8f8;
    --light3: #f9f9f9;
    --light2: #fdfdfd;
    --light: #fff;
}