.portfolio {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 70px 125px;
    box-sizing: border-box;
}

.portfolio__heading {
    margin: 0;
    padding: 0 0 50px;
    color: var(--dark4);
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
}

.portfolio__projects {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    list-style: none;
}

.portfolio__link {
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: #000;
    font-size: 30px;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: -1.2px;
    padding-bottom: 9px;
    border-bottom: 1px var(--light7) solid;
    text-decoration: none;
}

.portfolio__arrow {
    line-height: 60px;
}

.portfolio__link:hover {
    opacity: .7;
}

@media screen and (max-width: 768px) {
    .portfolio {
        padding: 0 50px 90px;
    }

    .portfolio__link {
        font-size: 28px;
        letter-spacing: -1.12px;
    }
}

@media screen and (max-width: 480px) {
    .portfolio {
        padding: 0 14px 70px;
    }

    .portfolio__heading {
        font-size: 14px;
    }

    .portfolio__link {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: -0.72px;
        padding-bottom: 19px;
    }

    .portfolio__arrow {
        line-height: inherit;
    }
}