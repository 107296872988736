.not-found {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 425px;
}

.not-found__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    width: 400px;
}

.not-found__title {
    color: var(--dark);
    text-align: center;
    font-size: 140px;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.not-found__subtitle {
    color: var(--dark);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.not-found__back-button {
    position: absolute;
    bottom: 0;
    margin: 0 auto 60px;
    color: var(--dark);
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}

.not-found__back-button:hover {
    opacity: .7;
}

@media screen and (min-height: 1000px) {
    .not-found__back-button {
        margin: 0 auto 222px;
    }
}

@media screen and (max-width: 480px) {
    .not-found__container {
        gap: 10px;
        width: 100%;
    }

    .not-found__title {
        font-size: 80px;
    }

    .not-found__subtitle {
        font-size: 12px;
    }

    .not-found__back-button {
        margin: 0 auto 30px;
    }
}