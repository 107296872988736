.techs {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 100px 70px;
    box-sizing: border-box;
    background-color: var(--light5);
}

.techs__section-heading {
    width: 100%;
    color: var(--dark);
    margin: 0;
    font-size: 22px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.88px;
    padding-bottom: 23px;
    border-bottom: 1px var(--dark) solid;
}

.techs__title {
    max-width: 600px;
    color: var(--dark);
    text-align: center;
    font-size: 50px;
    font-weight: 400;
    line-height: 58px;
    letter-spacing: -2px;
    padding: 90px 0 20px;
    margin: 0;
}

.techs__subtitle {
    text-align: center;
    max-width: 460px;
    color: var(--dark);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.56px;
    margin: 0;
    padding-bottom: 100px;
}

.techs__techs-wrapper {
    display: grid;
    grid-template: 60px / repeat(7, 90px);
    gap: 10px;
    margin: 0;
    padding: 0 10px;
}

.techs__technology {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--dark);
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.56px;
    margin: 0;
    background-color: var(--light6);
    border-radius: 10px;
}

@media screen and (max-width: 920px) {
    .techs__techs-wrapper {
        grid-template: 50px / repeat(7, 75px);
    }

    .techs__technology {
        font-size: 12px;
        letter-spacing: -0.48px;
    }
}

@media screen and (max-width: 768px) {
    .techs {
        padding: 90px 50px;
    }

    .techs__title {
        padding: 80px 0 22px;
    }

    .techs__subtitle {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.48px;
        padding-bottom: 83px;
    }
}

@media screen and (max-width: 767px) {
    .techs__techs-wrapper {
        grid-template: repeat(2, 57px) / repeat(4, 84px);
    }
}

@media screen and (max-width: 512px) {
    .techs__techs-wrapper {
        grid-template: repeat(3, 57px) / repeat(3, 84px);
    }
}


@media screen and (max-width: 480px) {
    .techs {
        padding: 90px 14px 80px;
    }

    .techs__subtitle {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.48px;
        width: 100%;
    }

    .techs__techs-wrapper {
        grid-template: repeat(4, 57px) / repeat(2, 84px);
    }
}