.about-me {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 110px 70px 100px;
    box-sizing: border-box;
}

.about-me__section-heading {
    width: 100%;
    color: var(--dark);
    margin: 0;
    font-size: 22px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.88px;
    padding-bottom: 23px;
    border-bottom: 1px var(--dark) solid;
}

.about-me__profile-wrapper {
    padding: 66px 0 0;
    display: flex;
    justify-content: space-between;
}

.about-me__profile {
    display: flex;
    flex-direction: column;
}

.about-me__profile-pic {
    height: 327px;
    border-radius: 10px;
}

.about-me__name {
    margin: 0;
    color: var(--dark);
    font-size: 50px;
    font-weight: 400;
    line-height: 58px;
    letter-spacing: -2px;
}

.about-me__description {
    padding-top: 18px;
    margin: 0;
    color: var(--dark);
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
}

.about-me__bio {
    padding: 26px 26px 0 0;
    margin: 0;
    color: var(--dark);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    max-width: 600px;
}

.about-me__link {
    padding-top: 40px;
    margin-top: auto;
    color: var(--dark);
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}

.about-me__link:hover {
    opacity: .7;
}

@media screen and (max-width: 768px) {
    .about-me {
        padding: 90px 50px;
    }

    .about-me__name {
        color: var(--dark);
        font-size: 40px;
        line-height: 40px;
        letter-spacing: -1.6px;
    }

    .about-me__description {
        padding-top: 16px;
        font-size: 12px;
        line-height: 18px;
    }

    .about-me__bio {
        max-width: 364px;
        font-size: 12px;
        line-height: 18px;
    }

    .about-me__profile-pic {
        margin-left: 10px;
        max-height: 307px;
    }
}

@media screen and (max-width: 612px) {
    .about-me__bio {
        max-width: 600px;
        padding: 20px 20px 0 0;
        max-width: 100%;
    }

    .about-me__description {
        padding-top: 20px;
    }

    .about-me__profile-wrapper {
        padding: 60px 0 0;
        flex-direction: column-reverse;
    }

    .about-me__profile-pic {
        margin: 0 0 40px;
        max-width: 548px;
        max-height: 730px;
        width: auto;
        height: auto;
    }
}

@media screen and (max-width: 480px) {
    .about-me {
        padding: 70px 14px;
    }

    .about-me__bio {
        font-size: 11px;
        line-height: 16px;
        padding: 20px 0 0 0;
    }
}