.search {
    padding: 70px 0 80px;
    margin: 0 70px 69px;
    border-bottom: 1px var(--light7) solid;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.search__form {
    display: flex;
    gap: 19px;
    justify-content: space-between;
    align-items: center;
    padding-left: 63px;
    padding-right: 245px;
    box-sizing: border-box;
    border-bottom: 1px var(--light7) solid;
    border: none;
    min-height: 72px;
    width: 100%;
    border-radius: 9px;
    background-image: url(../../images/search.svg);
    background-position: left 19px center;
    background-repeat: no-repeat;
    background-color: var(--light2);
    box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.08);
}

.search__form-input {
    color: var(--dark);
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    border: 0;
    width: inherit;
    outline: none;
}

.search__form-input::placeholder {
    color: var(--dark4);
}

.search__form-button {
    margin-left: auto;
    border: 0;
    color: var(--light);
    color: #FFF;
    font-size: 11px;
    font-weight: 500;
    line-height: 18px;
    background-color: var(--dark);
    border-radius: 48px;
    padding: 8px 10px;
    cursor: pointer;
}

.search__form-button:hover {
    opacity: .8;
}

@media screen and (max-width: 768px) {
    .search {
        padding: 80px 0 109px;
        margin: 0 30px 10px;
    }
}

@media screen and (max-width: 600px) {
    .search {
        padding: 80px 0 0;
    }

    .search__form-input {
        font-size: 14px;
    }

    .search__form {
        padding: 0 19px;
        background: none;
    }
}

@media screen and (max-width: 480px) {
    .search {
        margin: 0 10px;
    }
}